









import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import AddAudience from "@/views/Mine/MoreServices/Audience/AddAudience.vue";

@Component({
  components: {
    AddAudience,
  },
})
export default class ExternalAddAudiencePage extends Mixins(Mixin) {
  mounted() {
    this.$nextTick(() => {
      let addAudience = this.$refs["add-audience"] as AddAudience;
      if (addAudience) {
        addAudience.openAdd(true);
      }
    });
  }
  // 刷新观演人列表
  refreshAduience(): void {
    this.jsBridge("onSaveAudience", "");
  }
  back(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
}
