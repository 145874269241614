



























































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Field, Popup, Picker, Cell, Switch, Icon, Image } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Icon.name]: Icon,
    [Image.name]: Image,
  },
})
export default class AddAudience extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: String,
  })
  from!: string;
  public addOnce = 0; //避免重复添加观影人
  public showPicker = false;
  public certType: Array<any> = [];
  public form = {
    name: "",
    credentialsType: "身份证",
    credentialsCode: "",
    audienceStatus: false,
    id: "",
  };
  public canAdd = true;
  public choseCertEnum: member.Viewer["cardTypeEnum"] = "ID_CERT";
  // 添加
  openAdd(isAdd: boolean): void {
    this.clearForm();
    this.getCertType();
    this.canAdd = isAdd;
  }
  openEdit(params: {
    name: string;
    credentialsType: string;
    credentialsCode: string;
    certTypeEnum: member.Viewer["cardTypeEnum"];
    audienceStatus: boolean;
    id: string;
  }): void {
    this.clearForm();
    this.form = params;
    this.choseCertEnum = params.certTypeEnum;
  }
  // 获取证件类型
  getCertType(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "performance.codeBook",
      apiMethod: this.$api.dictionaryApi.performance.codeBook,
      callback: ({ data }) => {
        if (data.CertTypeEnum) {
          this.certType = data.CertTypeEnum.map((item: any) => {
            return {
              code: item.code,
              text: item.describe,
              value: item.name,
            };
          });
        }
      },
    });
  }
  // 置顶观演人
  changeSwitch(val: boolean): void {
    if (this.form.id) {
      if (val === true) {
        this.$api.memberApi.viewer.updateViewerTop(
          this.form.id!,
          ({ data }) => {
            if (data) {
              if (this.$route.name == "audience-list") {
                monitorEvent("MyContact_UpContact", "成功置顶观演人"); // 埋点：常用观演人，成功置顶观演人
              }
              this.form.audienceStatus = true;
            }
          }
        );
      } else {
        this.form.audienceStatus = false;
      }
    }
  }

  // 校验是否可以继续添加观演人
  saveAudience(): void {
    this.addOnce++; //防止连续点击造成的重复添加接口调用
    if (this.addOnce === 1) {
      if (this.checkName() && this.checkCode()) {
        this.saveView(this.form.id);
      }
    }
  }
  // 保存观演人
  saveView(id: string): void {
    if (!id && !this.canAdd) {
      this.addOnce = 0;
      this.$toast("观演人信息已达上限，删除不常用信息后继续添加");
      return;
    }

    let top = this.form.audienceStatus === true ? 1 : 0;
    this.$api.memberApi.viewer.saveViewer(
      {
        credentialsCode: this.form.credentialsCode,
        cardTypeEnum: this.choseCertEnum,
        name: this.form.name,
        id: id,
        top: top!,
      },
      ({ data }) => {
        if (data) {
          if (this.$route.name == "orderconfirm" && !id) {
            monitorEvent("ViewOrder_ClickAddSuccess", "成功新增观演人"); // 埋点：确认订单，成功新增观演人
          }
          if (this.AppSource == "harmonyOS" && this.from == "page") {
            this.$emit("save");
          } else {
            this.$emit("save");
            this.$emit("close");
          }
          this.clearForm();
          this.addOnce = 0;
        }
      }
    );
  }

  // 选择证件类型
  changeCert(value: any): void {
    this.form.credentialsType = value.text;
    this.choseCertEnum = value.value;
    this.showPicker = false;
  }
  cancel(): void {
    this.showPicker = false;
  }
  // 姓名校验
  checkName(): boolean {
    this.addOnce = 0;
    if (this.form.name && this.form.name.length <= 20) {
      return true;
    } else {
      this.$toast("请输入正确的观演人姓名");
      return false;
    }
  }
  // 证件号码校验
  checkCode(): boolean {
    this.addOnce = 0;
    if (
      this.form.credentialsType === "身份证" &&
      this.form.credentialsCode.length === 18
    ) {
      return true;
    } else if (
      this.form.credentialsType !== "身份证" &&
      this.form.credentialsCode &&
      this.form.credentialsCode.length < 50
    ) {
      return true;
    } else {
      this.$toast("请输入正确的证件号码");
      return false;
    }
  }
  clearForm(): void {
    window.document.title = "填写观演人信息";
    this.form.name = "";
    this.form.credentialsCode = "";
    this.form.credentialsType = "身份证";
    this.form.audienceStatus = false;
    this.form.id = "";
  }
}
