










































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  NavBar,
  Row,
  Cell,
  Col,
  SwipeCell,
  Button,
  Dialog,
  Image,
  Popup,
} from "vant";
import AddAudience from "./AddAudience.vue";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [SwipeCell.name]: SwipeCell,
    [Button.name]: Button,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    AddAudience,
  },
})
export default class AudienceList extends Mixins(Mixin) {
  public audienceList: Array<member.Commonly> = [];
  public AddViewer = false;
  public edit = false;
  public ViewerForm = {
    name: "",
    credentialsType: "",
    credentialsCode: "",
    isTop: 0,
    id: "",
  };
  public canAdd = true;
  value = 222;
  created(): void {
    this.getViewers();
  }

  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  // 获取观演人列表
  getViewers(): void {
    this.$api.memberApi.viewer.getViewers((data) => {
      this.audienceList = data.data;
      this.canAdd = data.data.length < 20 ? true : false;
    });
  }
  // 编辑观演人
  updateViewer(item: any): void {
    this.AddViewer = true;
    let params = {
      name: item.name || "",
      credentialsType: item.credentialsType || "",
      credentialsCode: item.credentialsCode || "",
      certTypeEnum: item.certTypeEnum || "",
      audienceStatus: item.top === 1,
      id: item.id || "",
    };
    this.$nextTick(() => {
      let addAudience = this.$refs["add-audience"] as AddAudience;
      if (addAudience) {
        addAudience.openEdit(params);
      }
    });
  }
  // 新增观演人
  AddViewers(): void {
    this.AddViewer = true;
    this.$nextTick(() => {
      let addAudience = this.$refs["add-audience"] as AddAudience;
      if (addAudience) {
        addAudience.openAdd(this.canAdd);
      }
    });
  }
  // 删除观演人
  deleteViewer(item: member.Commonly): void {
    Dialog.alert({
      title: "确定要删除此观演人吗？",
      showCancelButton: true,
    }).then(() => {
      this.$api.memberApi.viewer.deleteViewer(item.id!, (data) => {
        if (data.data) {
          this.getViewers();
        }
      });
    });
  }
  // 加密显示证件号码，保留前后三位
  deCode(str: string, frontLen = 3, endLen = 3): string {
    let len = str.length - frontLen - endLen;
    let start = "";
    for (var i = 0; i < len; i++) {
      start += "*";
    }
    // 最后的返回值由三部分组成
    return (
      str.substring(0, frontLen) + start + str.substring(str.length - endLen)
    );
  }
}
