




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import AudienceList from "@/views/Mine/MoreServices/Audience/AudienceList.vue";

@Component({
  components: {
    AudienceList,
  },
})
export default class ExternalAudienceListPage extends Mixins(Mixin) {}
